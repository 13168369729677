h1,h2,h3,h4,h5,h6
{
	margin: 1em 0 .25em;
}
ul,
ul li
{
	list-style: inside;
	margin: 0;
	padding: 0;
}
ul li ul
{
	margin-left: 1.5em;
}
.match-event
{
	margin-bottom: 1.5em;
}
.die
{
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin: 0 .5em .5em 0;
	border: 1px solid;
	width: 50px;
	height: 50px;
}

.flag
{
	margin: 0;
	padding: 0;
	width: 40px;
}
.flag img
{
	display: block;
	width: 100%;
	height: auto;
}

.fixture-player-flag
{
	display: flex;
}
.fixture-player-flag .flag
{
	width: 28px;
}
.fixture-player-profile .available
{
	display: none;
}